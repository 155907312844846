import { ErrorPage } from "app/components/ErrorPage"

type ServerErrorPageProps = {
  code: number
  message: string
}

export function ServerErrorPage(props: ServerErrorPageProps) {
  return <ErrorPage {...props} />
}
